.rotate {
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.rotate.down {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.expandable {
  overflow: hidden;
  height: 0;
  transition: all .2s ease-in-out;
}

.shape-dragging {
  cursor: move;
}

.shape-drop-target {
  border-top: 4px solid rgba(141, 171, 96, 75%);
}

.shape-selected {
  background-color: #eee;
}

.picker-label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(58, 62, 65);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0px;
  display: block;
  padding: 5px 0px;
  overflow-wrap: break-word;
}

.search-label {
  margin: 0px;
  display: inline-block;
  padding: 5px 0px;
  overflow-wrap: break-word;
}

.tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0;
}

.tag-item {
  font-size: 0.85em;
  font-weight: 400 !important;
  color: rgb(58, 62, 65);
  margin: 0 4px 4px 0;
  padding: 3px 6px;
  display: inline-block;
  overflow-wrap: break-word;
  background-color: #eee;
  cursor: pointer;
  line-height: 16px;
}

.tag-item.active {
  color: white;
  background-color: #777;
}


.x6-widget-snapline {
  stroke-width: 5px !important;
  color: #8dab60 !important;
  opacity: 0.5 !important;
  z-index: 4000 !important;
}

svg.drop-not-ok {
  background: var(--shape-drop-not-allowed-fill-color) !important;
}

svg.new-parent {
  background: var(--shape-drop-allowed-fill-color) !important;
}


/* Override move cursor during drop and connect */
.drop-not-ok,
.connect-not-ok,
.drop-not-ok *,
.connect-not-ok * {
  cursor: not-allowed !important;
}

.drop-ok,
.connect-ok,
.drop-ok *,
.connect-ok * {
  cursor: default !important;
}

.style-swatch {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 0 0 4px;
  vertical-align: middle;
  border: 1px solid #777;
  border-radius: 2px;
  cursor: pointer;
  line-height: 26px;
  text-align: center;
}

.colour-picker-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 2px 0px, rgba(0, 0, 0, 0.11) 0px 1px 4px 0px;
  background-color: rgb(250, 252, 247) !important;
  border: 1px solid #ccc;
  cursor: pointer !important;
  z-index: 3000;
}

.rotate {
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.rotate.down {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.expandable {
  overflow: hidden;
  height: 0;
  transition: all .2s ease-in-out;
}

.shape-dragging {
  cursor: move;
}

.shape-drop-target {
  border-top: 4px solid rgba(141, 171, 96, 75%);
}

.shape-selected {
  background-color: rgba(141, 171, 96, 75%) !important;
}

.picker-label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(58, 62, 65);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0px;
  display: block;
  padding: 5px 0px;
  overflow-wrap: break-word;
}

.search-label {
  margin: 0px;
  display: inline-block;
  padding: 5px 0px;
  overflow-wrap: break-word;
}

.tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0;
}

.tag-item {
  font-size: 0.85em;
  font-weight: 400 !important;
  color: rgb(58, 62, 65);
  margin: 0 4px 4px 0;
  padding: 3px 6px;
  display: inline-block;
  overflow-wrap: break-word;
  background-color: #eee;
  cursor: pointer;
  line-height: 16px;
}

.tag-item.active {
  color: white;
  background-color: #777;
}

